// jQuery:
import $, { when } from "jquery";
// const jquery = require("jquery");
window.$ = window.jQuery = $;

// Velocity Animations
// import Velocity from 'velocity-animate';
// import 'velocity-animate/velocity.js';

// fait styles:
import main from "./scss/autoload.scss";
// import linkbar from './js/linkbar';

// <!-- Add the slick-theme.css if you want default styling -->
// <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/gh/kenwheeler/slick@1.8.1/slick/slick.css"/>
// <!-- Add the slick-theme.css if you want default styling -->
// <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/gh/kenwheeler/slick@1.8.1/slick/slick-theme.css"/>

(function ($) {
  document.addEventListener("DOMContentLoaded", (v) => {
    // Datepicker
    if (document.URL.match("longstay") == null) {
      import("./js/datepicker.mjs");
    }

    let $navicon = $(".hamburger-icon > a > .icon"),
      $menuscreen = $("#menuscreen");
    console.log($navicon);
    $($navicon).on("click", function () {
      $navicon.toggleClass("open");
      console.log(this);
      $($menuscreen).toggleClass("open");
    });

    // Scroll Recognition

    $(window).on("scroll", () => {
      if (window.scrollY >= 60) {
        if (!$("body").hasClass("scrolled")) {
          $("body").toggleClass("scrolled");
        }
      } else if (window.scrollY <= 60) {
        if ($("body").hasClass("scrolled")) {
          $("body").toggleClass("scrolled");
        }
      }
    });

    // Lozad
    const $lozad = document.getElementsByClassName("lozad");
    if ($lozad.length) {
      import("lozad").then(() => {
        const observer = lozad(); // lazy loads elements with default selector as '.lozad'
        observer.observe();
      });
    }

    // Scrolldown
    const $scrolldown = $(document.getElementById("scrolldown"));
    $scrolldown.click((e) => {
      // const $headerwrapper = $(document.getElementById('headerwrapper'));
      // console.log($headerwrapper.next().offset().top);
      console.log(
        document.getElementById("headerwrapper").nextElementSibling.offsetTop
      );
      const offsetJumpTo =
        document.getElementById("headerwrapper").nextElementSibling.offsetTop;
      $("body, html").animate(
        {
          scrollTop: offsetJumpTo - 200,
        },
        800
      );
      // Velocity(document.getElementById('headerwrapper').nextElementSibling, 'scroll', {
      //   duration: 800,
      //   offset: -200,
      // });
      e.preventDefault();
    });

    // Scrollup
    $("footer").prepend('<div id="scrollup"></div>');
    $(document.getElementById("scrollup")).click((e) => {
      e.preventDefault();
      $("body, html").animate(
        {
          scrollTop: 0,
        },
        800
      );
    });

    const $anchor = $(".anchor");

    // Slider MFP magnificpopup
    var slider = $('div[class*="slickslideeer"');
    console.log("$slider", slider);
    if (slider.length) {
      import("./js/slickslider.mjs").then((script) => {
        script.sliders(slider);
        import("magnific-popup").then((mfp) => {
          console.log(
            "mfp slider",
            slider.find(
              ".slick-slide:not(.slick-cloned) .imgwrapper > .is_galerie"
            )
          );
          slider
            .find(
              ".swiper-slide:not(.slick-cloned) .imgwrapper > .is_galerie > a"
            )
            .magnificPopup({
              type: "image",
              titleSrc: "title",
              // delegate: "a",
              preload: [1, 2],
              tCounter: '<span class="mfp-counter">%curr% / %total%</span>',
              gallery: {
                enabled: true,
                tCounter: "%curr% / %total%",
              },
              callbacks: {
                open: function () {
                  console.log(this, $(this));
                  this.container.on("wheel", (e) => {
                    console.log(this);
                    console.log(e);
                    if (e.originalEvent.deltaY > 0) this.next();
                    if (e.originalEvent.deltaY < 0) this.prev();
                  });
                },
              },
            });
          import("magnific-popup/dist/magnific-popup.css");
        });
      });
    }

    // linkbar.init('.hamburger-icon', '#languages');

    function toggleAccordion(link) {
      const _this = link;
      let $openId = $(_this).data("accopenid");
      let $vault = $(".acc-vault." + $openId);
      // console.log('accoopenid', $(this).data());
      // console.log('find', '#'+$openId);
      // console.log($vault);
      // console.log(this);
      if (!$(_this).hasClass("open")) {
        $vault.show();
        $(_this).toggleClass("open");
      } else {
        $(_this).removeClass("open");
        $vault.hide();
      }
    }
    let $accKeys = document.querySelectorAll(".acc-key");
    if ($accKeys.length) {
      let $accLinks = $($accKeys).find('a[href^="#"]');

      $accLinks.on("click", function (e) {
        toggleAccordion(this);
        e.preventDefault();
      });
    }
  });
  console.log("after document.ready");
})(jQuery);
